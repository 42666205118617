"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import BurgerMenu from "./BurgerMenu";
import ProfileMenu from "./ProfileMenu";
import { signOut } from "@/features/auth/actions";
import { useRouter } from "next/navigation";

export default function Header({ currentUser }: { currentUser: any }) {
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showProfileMenu, setShowProfileMenu] = useState(false);

	const router = useRouter();

	const navLinks = [
		{
			name: "Home",
			href: "/",
		},
		{
			name: "How To Play",
			href: "/about",
		},
		{
			name: "League Tables",
			href: "/leagues/public",
		},
		{
			name: "Fixtures",
			href: "/fixtures",
		},
	];

	const handleSignOut = async () => {
		signOut();
		router.refresh();
	};

	return (
		<header className='flex relative items-center justify-between bg-white w-full shadow-md p-4'>
			<Link href='/'>
				<Image src='/asf-logo.jpeg' alt='All Square Football' width={200} height={75} priority />
			</Link>
			<div className='hidden md:flex gap-8 lg:gap-16 xl:gap-20'>
				{navLinks.map((link) => (
					<Link
						key={link.name}
						href={link.href}
						className='text-lg lg:text-xl text-as-blue hover:text-as-red font-bold'
					>
						{link.name}
					</Link>
				))}
			</div>
			<div className='hidden md:grid grid-cols-2 gap-4 xl:gap-10'>
				{!currentUser && (
					<Link href='/login' className='bg-as-red rounded-full text-white text-center px-4 py-1'>
						Login
					</Link>
				)}
				{!currentUser && (
					<Link href='/signup' className='bg-as-blue rounded-full text-white text-center px-4 py-1'>
						Sign Up
					</Link>
				)}
				{currentUser && (
					<button
						style={{
							borderRadius: "50%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							color: "#fff", // Set your preferred text color
							fontWeight: "bold",
							fontSize: "18px", // Set your preferred font size
						}}
						onClick={() => setShowProfileMenu(!showProfileMenu)}
						className='bg-as-blue hover:bg-as-red w-[50px] h-[50px]'
					>
						{currentUser.displayName
							? currentUser.displayName
									.split(" ")
									.map((name: string) => name.charAt(0))
									.join("")
									.toUpperCase()
							: ""}
					</button>
				)}
			</div>
			<BurgerMenu
				onClick={() => {
					setShowMobileMenu(!showMobileMenu);
				}}
			/>
			{showMobileMenu && (
				<div className='flex flex-col w-full items-center justify-center bg-white bottom-0 left-0 translate-y-full md:hidden absolute border-b shadow-md pb-8 z-20'>
					<div className='flex flex-col w-full gap-4'>
						{navLinks.map((link) => (
							<Link
								key={link.name}
								href={link.href}
								className='w-full py-4 px-8 border-t border-b border-as-blue text-as-blue hover:border-as-red hover:text-as-red font-bold'
								onClick={() => setShowMobileMenu(false)}
							>
								{link.name}
							</Link>
						))}
						{!currentUser && (
							<Link
								href='/login'
								className='bg-as-red rounded-full text-white text-center px-4 py-1 mx-8'
								onClick={() => setShowMobileMenu(false)}
							>
								Login
							</Link>
						)}
						{!currentUser && (
							<Link
								href='/signup'
								className='bg-as-blue rounded-full text-white text-center px-4 py-1 mx-8'
								onClick={() => setShowMobileMenu(false)}
							>
								Sign Up
							</Link>
						)}
						{currentUser && (
							<Link
								href='/profile'
								className='w-full py-4 px-8 border-t border-b border-as-blue text-as-blue hover:border-as-red hover:text-as-red font-bold'
								onClick={() => setShowMobileMenu(false)}
							>
								My Profile
							</Link>
						)}
						{currentUser && (
							<button
								className='bg-as-blue hover:bg-as-red text-white rounded-full py-1 mx-8'
								onClick={() => handleSignOut()}
							>
								Sign Out
							</button>
						)}
					</div>
				</div>
			)}
			{currentUser && showProfileMenu && <ProfileMenu />}
		</header>
	);
}
