import { signOut } from "@/features/auth/actions";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";

export default function ProfileMenu() {
	const router = useRouter();
	const handleSignOut = async () => {
		signOut();
		router.refresh();
	};
	return (
		<div className='flex flex-col items-center justify-center absolute right-14 bottom-0 transform translate-y-full bg-white text-as-blue shadow-xl pb-4'>
			<Link
				href='/profile'
				className='w-full text-center mb-4 py-2 border-t border-b border-as-blue hover:border-as-red hover:text-as-red px-8'
			>
				My Profile
			</Link>
			<button
				className='bg-as-blue hover:bg-as-red text-white rounded-full py-1 w-24'
				onClick={() => handleSignOut()}
			>
				Sign Out
			</button>
		</div>
	);
}
